// Classes
import { RutTools } from '@/Classes/Static/RutTools'

// Constants
import { Component } from '@/Constants/Global/Component'

// Helpers
import { InputButton } from '@/Helpers/Components/DataForm/InputButton'
import { InputSelect } from '@/Helpers/Components/DataForm/InputSelect'
import { InputText }   from '@/Helpers/Components/DataForm/InputText'

// Input's Validator
const rutStateValidator = (self: any) => {
	if (self.value === '') return null
	return RutTools.validator(self.value)
}

// Input's Validator
const rutKeyUpEvent = (self: any) => {
	if (self.value === '') return
	self.value = RutTools.formatter(self.value, '')
}

// Form Export
export default <any> {
	Companies: {
		params: {
			currentTab: 'companies',
			numColumns: 2,
		},
		tabs: [
			{
				header: {
					key: 'companies',
					text: {
						onInsert: 'Nuevo Cliente',
						onUpdate: 'Actualizar Cliente'
					}
				},
				columns: [
					new InputText('code', 'Código')
						.setParamsReadOnly(Component.Actions.UPDATE)
					,
					new InputSelect('region', 'Región'),
					new InputText('rut', 'Rut')
						.addEvent('keyup', rutKeyUpEvent)
						.setParamsPlaceHolder('Ej: 99888777-6')
						.setParamsReadOnly(Component.Actions.UPDATE)
						.setParamsState(rutStateValidator)
					,
					new InputSelect('commune', 'Comuna')
						.setParamsPlaceHolder('- Seleccione una Región -')
					,
					new InputText('name', 'Razón Social'),
					new InputSelect('isClient', '¿Es Cliente?', ['Sí', 'No']),
					new InputText('address', 'Dirección de Facturación'),
					new InputSelect('isValid', '¿Esta Activa?', ['Sí', 'No']),
					new InputSelect('type', 'Tipo', ['Cliente', 'Proveedor', 'Sucursal Interna']),
				]
			}
		]
	},
	Storages: {
		params: {
			currentTab: 'storages',
			numColumns: 2,
		},
		tabs: [
			{
				header: {
					key: 'storages',
					text: {
						onInsert: 'Nueva Ubicación',
						onUpdate: 'Actualizar Ubicación'
					}
				},
				columns: [
					new InputText('code', 'Código')
						.setParamsReadOnly(Component.Actions.UPDATE)
					,
					new InputSelect('region', 'Región'),
					new InputText('codeLocation', 'Código Planta (SAP)')
						.setParamsReadOnly(Component.Actions.UPDATE)
					,
					new InputSelect('commune', 'Comuna')
						.setParamsPlaceHolder('- Seleccione una Región -')
					,
					new InputText('name', 'Nombre'),
					new InputButton('company', 'Cliente')
						.setParamsIcon('pen')
						.setParamsReadOnly(Component.Actions.UPDATE)
					,
					new InputText('address', 'Dirección'),
					new InputButton('technical', 'Técnico')
						.setParamsIcon('pen')
					,
					new InputSelect('isValid', '¿Esta Activa?', ['Sí', 'No']),
					new InputButton('userAdmin', 'Adm. Contrato')
						.setParamsIcon('pen')
				]
			}
		]
	}
}